_cruxUtils._cruxLocale = {
	"crm.criteria.secondaryfield.valid.check" : "\u8bf7\u63d0\u4f9b {0} \u7684\u5339\u914d\u503c",
	"crm.label.field":"\u5b57\u6bb5",//no i18n
"crm.label.value":"\u503c",//no i18n
	"sentiment.criteria.wrongcriteria":"\u6761\u4ef6\u503c\u4e0d\u80fd\u8d85\u8fc7{0}",//no i18n
"crm.mb.field.common.splc":"\u4e0d\u5141\u8bb8\u7279\u6b8a\u5b57\u7b26\u3002\u8bf7\u8f93\u5165\u6709\u6548\u503c\u3002",//no i18n
	"crm.label.field.plural":"\u5b57\u6bb5",//no i18n
	"crm.label.in.minutes":"{0}\uff08\u5206\uff09",//no i18n
	"crm.security.roles.list":"\u804c\u4f4d\u5217\u8868",//no i18n
"crm.security.roles.lookup.info":"\u8bf7\u4ece\u4e0b\u9762\u7684\u5217\u8868\u4e2d\u9009\u62e9\u4e00\u4e2a\u804c\u4f4d\u3002",//no i18n
"crm.territory.addterritory":"\u6dfb\u52a0\u533a\u57df",//no i18n
"crm.title.edit.territory":"\u7f16\u8f91\u533a\u57df",//no i18n
"crm.territory.title.assign.territories":"\u5206\u914d\u533a\u57df",//no i18n
	"crm.label.context.help":"\u5e2e\u52a9",//no i18n
	"crm.label.from":"\u4ece",//no i18n
"crm.label.to":"\u5230",//no i18n
	"workflow.option.webhookFailure.fromDate":"\u5f00\u59cb\u65f6\u95f4",//no i18n
"workflow.option.webhookFailure.toDate":"\u7ed3\u675f\u65f6\u95f4",//no i18n
"crm.custom.field.less.than.equalto":"{0}\u5e94\u8be5\u5c0f\u4e8e\u6216\u8005\u7b49\u4e8e{1}",//no i18n
	"crm.template.listview.search.no.results":"\u6ca1\u6709\u627e\u5230\u7ed3\u679c",//No I18n
	"crm.label.tag.new":"\u65b0\u5efa\u6807\u7b7e",//No I18n
	"crm.label.enter.tag":"\u8f93\u5165\u6807\u7b7e",//No I18n
	"Administrator":"\u7ba1\u7406\u5458",//No I18n
	"Standard":"\u6807\u51c6",//No I18n
	"crux.comboBox.max.limit":"\u60a8\u7684\u9009\u62e9\u4e0d\u80fd\u8d85\u8fc7{0} {1}.",//NO I18n
	"crm.button.add":"\u6dfb\u52a0",//NO I18n
	"crm.label.users":"\u7528\u6237", //NO I18n
  "crm.workflow.alert.roles":"\u804c\u4f4d", //NO I18n
  "crm.security.groups":"\u7ec4", //NO I18n
  "crm.wf.usage.date.criteria.error.msg":"\u5f00\u59cb\u65e5\u671f\u4e0d\u80fd\u5927\u4e8e\u7ed3\u675f\u65e5\u671f\u3002",//no i18n
	"crm.label.available" : "\u53ef\u9009\u7684", //NO I18n
	"crm.label.assign.manually" : "\u5206\u914d", //NO I18n
	"crm.globalsearch.option.all": "\u5168\u90e8", //NO I18n
	"webform.status.Active":"\u6fc0\u6d3b", //NO I18n
	"Inactive":"\u7981\u7528", //NO I18n
  "Confirmed":"\u5df2\u786e\u8ba4", //NO I18n
  "crm.user.component.unconfirmed":"\u672a\u786e\u8ba4\u7684",//no i18n
  "DeletedUser":"\u5220\u9664\u7684", //NO I18n
  "crm.feed.group.admin":"\u7ba1\u7406\u5458", //NO I18n
  "crm.ln.lable.current":"\u5f53\u524d", //NO I18n
	"crm.label.selected": "\u9009\u62e9\u7684",//NO I18n
	"crm.auditlog.user": "\u7528\u6237", //NO I18n
	"cob.role": "\u804c\u4f4d", //NO I18n
	"zoho.email": "\u90ae\u4ef6", //NO I18n
	"Profile": "\u89d2\u8272", //NO I18n
	"crm.security.group.users.empty": "\u6ca1\u6709\u627e\u5230\u7ec4", //NO I18n
	"crm.label.picklist.none": "--\u65e0--", //NO I18n
	"crm.usrpop.non.selected" : "\u9009\u4e2d\u7684\u7528\u6237",//NO I18n
	"crm.zti.label.user": "\u7528\u6237\u540d", //NO I18n
	"crm.label.notSelected" : "\u672a\u9009\u4e2d",//NO I18n
	"AM" : "\u4e0a\u5348",//NO I18n
	"Call" : "\u901a\u8bdd",//NO I18n
	"crm.phoneNo.Link.Title" : "\u4f7f\u7528Skype\u62e8\u53f7",//NO I18n
	"crm.button.cancel" : "\u53d6\u6d88",//NO I18n
	"crm.button.save" : "\u4fdd\u5b58",//NO I18n
	"crm.no.data.found" : "\u65e0\u641c\u7d22\u7ed3\u679c",//NO I18n
	"crm.label.no.options.found" : "\u672a\u627e\u5230\u9009\u9879",//No I18n
	"crm.globalsearch.search.title" : "\u641c\u7d22",//No I18n
	"None" : "\u65e0",//No I18n
	"crm.label.criteria.pattern" : "\u5339\u914d\u6761\u4ef6",//No I18n
	"crm.label.edit.criteria.pattern" : "\u7f16\u8f91\u6a21\u5f0f",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "\u6837\u5f0f\u7b26\u53f7\u4e0d\u5339\u914d",//No I18n
	"criteria.error.alert.brackets.invalid" : "\u6761\u4ef6\u8fd0\u7b97\u7b26\u4e2d\u542b\u6709\u65e0\u6548\u7684\u62ec\u53f7\u3002",//No I18n
	"crm.criteria.number.notmatch.check" : "\u8bf7\u5728{0}\u4e2d\u68c0\u67e5\u6761\u4ef6",//No I18n
	"criteria.error.alert.other.params" : "\u6b64\u6837\u5f0f\u4e2d\u5305\u542b\u4e0d\u53ef\u7528\u5185\u5bb9", //No I18n
	"crm.label.search.for.users": "\u641c\u7d22\u7528\u6237", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "\u6b64\u6837\u5f0f\u4e2d\u7684\u6761\u4ef6\u6570\u4e0e\u6761\u4ef6\u7f16\u8f91\u5668\u4e2d\u7684\u6761\u4ef6\u6570\u4e0d\u5339\u914d", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "\u6b64\u6837\u5f0f\u4e2d\u7684\u6761\u4ef6\u6570\u4e0e\u4f7f\u7528\u7684\u6761\u4ef6\u7f16\u8f91\u5668\u4e2d\u7684\u6761\u4ef6\u6570\u4e0d\u5339\u914d", //No I18n
	"and" : "\u548c", //No I18n
	"or" : "\u6216\u8005", //No I18n
	"crm.label.or" : "\u6216", //No I18n
	"crm.label.and" : "\u4e0e", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "\u8bf7\u5728\u884c{0}\u4e2d\u8f93\u5165\u53ef\u7528\u7684\u5b57\u6bb5\u6807\u7b7e", //No I18n
	"crm.criteria.condition.valid.check" : "\u8bf7\u8f93\u5165\u6570\u636e\u5b57\u6bb5{0}\u7684\u53ef\u7528\u6761\u4ef6", //No I18n
	"crm.field.valid.check" : "\u8bf7\u8f93\u5165\u6709\u6548\u7684{0}", //No I18n
	"crm.custom.field.less.than.to" : "<i>\u4ece</i>\u8303\u56f4\u4e0d\u80fd\u5927\u4e8e<i>\u5230</i>\u8303\u56f4\u3002", //No I18n
	"crm.alert.label.savepattern" : "\u8bf7\u5728\u4fee\u6539\u6761\u4ef6\u4e4b\u524d\u4fdd\u5b58\u6837\u5f0f",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "\u65e0\u6cd5\u6dfb\u52a0\u66f4\u591a\u6761\u4ef6",//No I18n
	"is" : "\u662f",//No I18n
	"isn\'t" : "\u4e0d\u662f",//No I18n
	"contains" : "\u5305\u542b",//No I18n
	"doesn\'t contain" : "\u4e0d\u5305\u542b",//No I18n
	"starts with" : "\u5f00\u59cb\u5b57\u7b26",//No I18n
	"ends with" : "\u7ed3\u675f\u5b57\u7b26",//No I18n
	"is empty" : "\u4e3a\u7a7a",//No I18n
	"is not empty" : "\u4e0d\u4e3a\u7a7a",//No I18n
	"is before" : "\u4e4b\u524d",//No I18n
	"is after" : "\u4e4b\u540e",//No I18n
	"between" : "\u5728..\u4e4b\u95f4",//No I18n
	"not between" : "\u4e0d\u5728..\u4e4b\u95f4",//No I18n
	"Today" : "\u4eca\u5929",//No I18n
	"Tommorow" : "\u660e\u5929",//No I18n
	"Tommorow Onwards" : "\u660e\u5929\u4e4b\u540e",//No I18n
	"Yesterday" : "\u6628\u5929",//No I18n
	"Till Yesterday" : "\u622a\u81f3\u6628\u5929",//No I18n
	"Last Month" : "\u4e0a\u6708",//No I18n
	"Current Month" : "\u672c\u6708", //No I18n
	"Next Month" : "\u4e0b\u6708", //No I18n
	"Last Week" : "\u4e0a\u5468", //No I18n
	"Current Week" : "\u672c\u5468", //No I18n
	"Next Week" : "\u4e0b\u4e00\u5468", //No I18n
	"Age in Days" : "\u6301\u7eed\u5929\u6570", //No I18n
	"Due in Days" : "\u51e0\u5929\u540e\u5230\u671f", //No I18n
	"Scheduled" : "\u5df2\u5b89\u6392", //No I18n
	"Attended Dialled" : "\u63a5\u542c\u7684\u6765\u7535", //No I18n
	"Unattended Dialled" : "\u672a\u63a5\u542c\u7684\u6765\u7535", //No I18n
	"Overdue" : "\u8fc7\u671f", //No I18n
	"Cancelled" : "\u53d6\u6d88", //No I18n
	"Received" : "\u5df2\u63a5\u6536", //No I18n
	"Missed" : "\u9519\u8fc7\u7684", //No I18n
	"crm.alert.character.not.allowed" : "\u4e0d\u652f\u6301{0}", //No I18n
	"crm.condition.in.last" : "\u5728\u6700\u8fd1", //No I18n
	"crm.zinvoice.dueIn" : "\u622a\u6b62\u4e8e", //No I18n
	"on" : "\u5728",//No I18n
	"before" : "\u4e4b\u524d",//No I18n
	"crm.label.general.small.after" : "\u4e4b\u540e",//No I18n
	"crm.thisweek" : "\u672c\u5468",//No I18n
	"crm.label.this.month" : "\u672c\u6708",//No I18n
	"crm.thisyear" : "\u4eca\u5e74",//No I18n
	"crm.source.user.and.system" : "\u7528\u6237\u548c\u7cfb\u7edf",//No I18n
	"crm.source.user.or.system" : "\u7528\u6237\u6216\u7cfb\u7edf",//No I18n
	"crm.label.system2" : "\u7cfb\u7edf",//No I18n
	"crm.source.user.only" : "\u4ec5\u7528\u6237",//No I18n
	"crm.source.system.only" : "\u4ec5\u7cfb\u7edf",//No I18n
	"crm.condition.till.today" : "\u5230\u4eca\u5929\u4e3a\u6b62",//No I18n
	"game.month.left" : "1\u4e2a\u6708",//No I18n
	"game.months.left" : "{0}\u4e2a\u6708",//No I18n
	"crm.condition.last.30.days" : "\u5728\u8fc7\u53bb30\u5929",//No I18n
	"crm.condition.last.60.days" : "\u5728\u8fc7\u53bb60\u5929",//No I18n
	"crm.condition.last.90.days" : "\u5728\u8fc7\u53bb90\u5929",//No I18n
	"crm.condition.until.now" : "\u76f4\u5230\u73b0\u5728",//No I18n
	"crm.label.filter.typehere" : "\u5728\u8fd9\u91cc\u8f93\u5165", //No I18N
	"crm.filter.is.not" : "\u4e0d\u662f", //No I18n
	"crm.filter.email.isblocked" : "\u88ab\u9501\u5b9a",//No I18n
	"crm.filter.email.isnotblocked" : "\u672a\u88ab\u9501\u5b9a",//No I18n
	"crm.label.no.results.match" : "\u6ca1\u6709\u5339\u914d\u7ed3\u679c",//No I18n
	"crm.label.select.user" : "\u70b9\u51fb\u9009\u62e9\u7528\u6237", //No I18n
	"current.logged.in.user": "\u767b\u5f55\u7528\u6237", //NO I18n
	"current.logged.in.user.definition": "\u53d1\u8d77\u8bb0\u5f55\u64cd\u4f5c\u7684\u7528\u6237\u3002", //NO i18n
	"crm.security.group": "\u7ec4", //NO I18n
	"crm.security.role": "\u804c\u4f4d", //NO I18n
	"Date" : "\u65e5\u671f",//No I18n
	"crm.field.valid.decimal.check2" : "<i>{0} </i>\u5b57\u6bb5\u7684\u5c0f\u6570\u4f4d\u6570\u5e94\u8be5\u5c0f\u4e8e\u6216\u7b49\u4e8e{1}\u4f4d\u3002",//No I18n
	"crm.field.empty.check" : "{0}\u4e0d\u80fd\u4e3a\u7a7a",//No I18n
	"crm.label.add.note": "\u6dfb\u52a0\u5907\u6ce8", //NO I18n
	"crm.label.simply.by": "\u7531", //NO I18n
	"crm.general.addnote": "\u6dfb\u52a0\u5907\u6ce8", //NO I18n
	"crm.general.addtitle": "\u6dfb\u52a0\u6807\u9898", //NO I18n
	"crm.label.attach.file": "\u6dfb\u52a0\u9644\u4ef6", //NO I18N
	"crm.button.mass.delete": "\u5220\u9664", //NO I18N
	"crm.warning.delete.record": '\u60a8\u786e\u5b9a\u8981\u79fb\u52a8"{0}"\u5230\u56de\u6536\u7ad9\u5417\uff1f', //NO I18N
	"crm.label.yes": "\u662f", //NO I18N
	"crm.note.view.previous": "\u67e5\u770b\u4e4b\u524d\u7684\u5907\u6ce8", //NO I18N
  "of": "-", //NO I18N
	"crm.label.notes": "\u5907\u6ce8", //NO I18N
	"crm.note.recent.first": "\u964d\u5e8f", //NO I18N
	"crm.note.recent.last": "\u5347\u5e8f", //NO I18N
	"crm.territory.label.only": "\u4ec5{0}", //no i18n
	"crm.select" : "\u9009\u62e9",//No I18n
	"crm.button.apply.filter" : "\u5e94\u7528\u8fc7\u6ee4\u5668",//No I18n
	"crm.alert.maximum.text.values.contains" : "\u60a8\u4e0d\u80fd\u4e3a\u6b64\u5b57\u6bb5\u8f93\u5165\u8d85\u8fc7{0}\u503c\u3002",//No I18n
	"PM" : "\u4e0b\u5348",//No I18n
	"crm.mb.newversion.msg4" : "\u597d\u7684\uff0c\u4e86\u89e3",//No I18n
	"Jan" : "\u4e00\u6708",//No I18n
	"Feb" : "\u4e8c\u6708",//No I18n
	"Mar" : "\u4e09\u6708",//No I18n
	"Apr" : "\u56db\u6708",//No I18n
	"Jun" : "\u516d\u6708",//No I18n
	"Jul" : "\u4e03\u6708",//No I18n
	"Aug" : "\u516b\u6708",//No I18n
	"Sep" : "\u4e5d\u6708",//No I18n
	"Oct" : "\u5341\u6708",//No I18n
	"Nov" : "\u5341\u4e00\u6708",//No I18n
	"Dec" : "\u5341\u4e8c\u6708",//No I18n
	"crm.label.More" :"\u66f4\u591a", //no i18n
	"crm.label.unmapped.stages":"\u672a\u8bf4\u660e\u7684", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "\u663e\u793a\u66f4\u591a",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "\u663e\u793a\u66f4\u5c11",//No I18n

	 	 //filter related keys-start
	"crm.lead.prediction.convert.high":"\u9ad8",//no i18n
"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
"crm.lead.prediction.convert.medium":"\u4e2d",//no i18n
"crm.lead.prediction.convert.low":"\u4f4e",//no i18n
"crm.lead.prediction.tooltip.convert":"\u8f6c\u6362",//no i18n
"crm.lead.prediction.tooltip.score":"\u5f97\u5206",//no i18n
"Planned":"\u8ba1\u5212\u4e2d",//no i18n
"Invited":"\u5df2\u9080\u8bf7",//no i18n
"Sent":"\u5df2\u53d1\u9001",//no i18n
"Received":"\u5df2\u63a5\u6536",//no i18n
"Opened":"\u5df2\u6253\u5f00",//no i18n
"Responded":"\u5df2\u56de\u590d",//no i18n
"Bounced":"\u5df2\u62d2\u7edd",//no i18n
"Opted\ Out":"\u9009\u62e9\u9000\u51fa",//no i18n
"crm.filter.label.with.open":"\u4f7f\u7528\u6253\u5f00{0}",//no i18n
"crm.filter.label.without.open":"\u6ca1\u6709\u8fdb\u884c\u4e2d\u7684{0}",//no i18n
"crm.filter.label.without.any":"\u6ca1\u6709\u4efb\u4f55{0}",//no i18n
"crm.filter.label.with.module":"\u4f7f\u7528{0}",//no i18n
"crm.filter.label.activity.due":"{0}\u5230\u671f",//no i18n
"crm.filter.label.activity.done":"{0}\u5b8c\u6210",//no i18n
"Notes":"\u5907\u6ce8",//no i18n
"crm.filter.label.notes.added":"\u6dfb\u52a0\u7684\u5907\u6ce8",//no i18n
"crm.label.filter.email.status":"\u6700\u65b0\u90ae\u4ef6\u72b6\u6001",//no i18n
"crm.label.filter.email.clicked":"\u5df2\u70b9\u51fb",//no i18n
"crm.label.filter.email.responded":"\u5df2\u56de\u590d", //no i18n
"crm.label.filter.email.info":"\u6839\u636e\u5df2\u53d1\u9001/\u5df2\u63a5\u6536\u7535\u5b50\u90ae\u4ef6\u7684\u6700\u65b0\u72b6\u6001\u8fc7\u6ee4\u8bb0\u5f55\u3002",//no i18n
"crm.filter.label.sent":"\u5df2\u53d1\u9001",//no i18n
"crm.filter.label.not.sent":"\u672a\u53d1\u9001",//no i18n
"crm.filter.label.opened":"\u5df2\u6253\u5f00",//no i18n
"crm.filter.label.not.opened":"\u672a\u6253\u5f00",//no i18n
"crm.filter.label.received":"\u5df2\u63a5\u6536",//no i18n
"crm.filter.label.not.received":"\u672a\u63a5\u6536",//no i18n
"crm.filter.label.bounced":"\u5df2\u9000\u4fe1",//no i18n
"crm.filter.label.opened.not.replied":"\u5df2\u6253\u5f00\u4f46\u672a\u56de\u590d", //no i18n
"crm.filter.label.any":"\u4ee5\u4e0a\u4efb\u610f",//no i18n
"crm.zia.config.potential.amount":"{0}\u91d1\u989d",//no i18n
"Quote\ Stage":"{0}\u9636\u6bb5",//no i18n
"crm.module.owner":"{0}\u6240\u6709\u8005",//no i18n
"Potential\ Closing\ Date":"{0}\u9884\u8ba1\u6210\u4ea4\u65e5\u671f",//no i18n
"crm.lead.prediction.likely.convert":"\u53ef\u80fd\u8f6c\u6362",//no i18n
"crm.predictions.feature.label":"\u9884\u6d4b",//no i18n
"crm.intelligence.prediction.likelywin":"\u53ef\u80fd\u8d62\u5f97",//no i18n
"crm.intelligence.prediction.likelylose":"\u53ef\u80fd\u5931\u53bb",//no i18n
"crm.intelligence.prediction.halfchance":"\u4e00\u534a\u7684\u673a\u4f1a",//no i18n
"crm.intelligence.prediction.score":"\u9884\u6d4b\u79ef\u5206",//no i18n
"crm.lead.prediction.recent.score":"\u6700\u8fd1\u7684\u9884\u6d4b\u5206\u6570",//no i18n
"crm.intelligence.prediction.lastconv":"\u6700\u540e3\u6b21\u5bf9\u8bdd",//no i18n
"crm.intelligence.prediction.recordsfocus":"\u91cd\u70b9\u8bb0\u5f55",//no i18n
"crm.intelligence.prediction.slowmoving":"\u7f13\u6162\u79fb\u52a8",//no i18n
"crm.intelligence.prediction.trend.down":"\u6700\u8fd1\u8d8b\u52bf\u4e0b\u964d",//no i18n
"crm.label.touched.records":"\u53d7\u5f71\u54cd\u7684\u8bb0\u5f55",//no i18n
"crm.label.untouched.records":"\u672a\u53d7\u5f71\u54cd\u7684\u8bb0\u5f55",//no i18n
"crm.label.record.action":"\u8bb0\u5f55\u64cd\u4f5c",//no i18n
"workflow.rule.view.label.Modified":"\u5df2\u4fee\u6539",//no i18n
"crm.label.not.modified":"\u672a\u4fee\u6539",//no i18n
"crm.label.related.records.action":"\u76f8\u5173\u7684\u64cd\u4f5c\u8bb0\u5f55",//no i18n
"Done":"\u5b8c\u6210",//no i18n
"crm.label.not.done":"\u5c1a\u672a\u5b8c\u6210",//no i18n
"sentiment.model":"\u7535\u5b50\u90ae\u4ef6\u89c2\u70b9\u6a21\u578b",//no i18n
"sentiment.criteria.count":"\u6570",//no i18n
"sentiment.criteria.percentage":"\u767e\u5206\u6bd4",//no i18n
"sentiment.criteria.lastmail":"\u6700\u540e\u4e00\u5c01\u90ae\u4ef6",//no i18n
"Chats":"\u804a\u5929",//no i18n
"Attended":"\u5df2\u53c2\u4e0e",//no i18n
"crm.lead.prediction.popup.text":"\u5f53\u53ef\u80fd\u8f6c\u6362",//no i18n
"crm.lead.prediction.popup.final":"\u8bf7\u76f8\u5e94\u5730\u66f4\u6539\u8fc7\u6ee4\u5668\uff0c\u7136\u540e\u91cd\u8bd5\u3002",//no i18n
"crm.custom.field.less.than.to1":"\u8303\u56f4\u7684\u8d77\u59cb\u503c\u4e0d\u80fd\u5927\u4e8e\u7ed3\u675f\u503c\u3002",//no i18n
"Last\ Activity\ Date":"\u6700\u8fd1\u64cd\u4f5c\u65e5\u671f",//no i18n
"crm.label.vendor.name":"{0}\u540d\u79f0",//no i18n
"hours":"\u5c0f\u65f6",//no i18n
"days":"\u5929",//no i18n
"weeks":"\u5468",//no i18n
"months":"\u6708",//no i18n
"years":"\u5e74",//no i18n
"crm.label.general.small.after":"\u4e4b\u540e",//no i18n
"Last\ Week":"\u4e0a\u5468",//no i18n
"Last\ Month":"\u4e0a\u6708",//no i18n
"crm.module.name":"{0}\u540d\u79f0",//no i18n
"Campaign":"\u5e02\u573a\u6d3b\u52a8",//no i18n
"Tasks":"\u4efb\u52a1",//no i18n
"Calls":"\u901a\u8bdd",//no i18n
"Events":"\u6d3b\u52a8",//no i18n
"sentiment.criteria.wrongcriteria":"\u6761\u4ef6\u503c\u4e0d\u80fd\u8d85\u8fc7{0}",//no i18n
"crm.chosen.minimum.input.text":"\u8bf7\u8f93\u5165{0}\u4e2a\u6216\u66f4\u591a\u7684\u5b57\u7b26",//no i18n
"crm.intelligence.prediction.trendup":"\u4e0a\u5347\u8d8b\u52bf",//no i18n
"crm.intelligence.prediction.trenddown":"\u4e0b\u964d\u8d8b\u52bf",//no i18n
"crm.zia.prediction.notrend":"\u6ca1\u6709\u8d8b\u52bf",//no i18n
"crm.zia.prediction.likelihood":"\u53ef\u80fd\u6027\u5f97\u5206",//no i18n
"Completed":"\u5b8c\u6210" ,//no i18n
"crm.label.success":"\u6210\u529f" ,//no i18n
"crm.label.Failure":"\u5931\u8d25" ,//no i18n
"Both":"\u4e24\u8005\u540c\u65f6" ,//no i18n
"crm.condition.cannot.empty":"\u6761\u4ef6\u4e0d\u80fd\u4e3a\u7a7a\u3002",//no i18n
"crm.condition.last.30.days":"\u5728\u8fc7\u53bb30\u5929",//no i18n
"crm.condition.last.60.days":"\u5728\u8fc7\u53bb60\u5929",//no i18n
"crm.condition.last.90.days":"\u5728\u8fc7\u53bb90\u5929",//no i18n
"crm.sentiment.Positive":"\u79ef\u6781",//no i18n
"crm.sentiment.Negative":"\u6d88\u6781",//no i18n
"sentiment.positiveandnegative":"\u6b63\u6570\u548c\u8d1f\u6570",//no i18n
"sentiment.positiveornegative":"\u6b63\u6570\u6216\u8d1f\u6570",//no i18n
"sentiment.positiveonly":"\u4ec5\u6b63\u6570",//no i18n
"sentiment.negativeonly":"\u4ec5\u8d1f\u6570",//no i18n
"crm.sentiment.Neutral":"\u4e2d\u6027",//no i18n
"crm.filters.select.campaign.type":"\u9009\u62e9{0}\u7684\u7c7b\u578b",//no i18n
"crm.filters.select.campaign.status":"\u9009\u62e9{0}\u7684\u72b6\u6001",//no i18n
"campaign.Member" : "\u6210\u5458",//no i18n
	"Service":"\u670d\u52a1",//no i18n
"Activities":"\u4efb\u52a1\u3001\u901a\u8bdd\u53ca\u6d3b\u52a8",//no i18n
"crm.livedesk.pot.nextdays":"\u672a\u6765{0}\u5929",//no i18n
"Today\ +\ Overdue":"\u4eca\u65e5+\u8fc7\u671f",//no i18n
"crm.source.user.and.system":"\u7528\u6237\u548c\u7cfb\u7edf",//no i18n
"crm.source.user.or.system":"\u7528\u6237\u6216\u7cfb\u7edf",//no i18n
"User":"\u7528\u6237",//no i18n
"crm.source.user.only":"\u4ec5\u7528\u6237",//no i18n
"crm.source.system.only":"\u4ec5\u7cfb\u7edf",//no i18n
"Scheduled":"\u5df2\u5b89\u6392",//no i18n
"Attended\ Dialled":"\u63a5\u542c\u7684\u6765\u7535",//no i18n
"Unattended\ Dialled":"\u672a\u63a5\u542c\u7684\u6765\u7535",//no i18n
"Cancelled":"\u53d6\u6d88",//no i18n
"crm.filter.email.isblocked":"\u88ab\u9501\u5b9a",//no i18n
"crm.filter.email.isnotblocked":"\u672a\u88ab\u9501\u5b9a",//no i18n
"condition.till.now":"\u76f4\u5230\u73b0\u5728",//no i18n
"crm.recurring.no.months":"{0}\u6708",//no i18n
"crm.lead.prediction.tooltip":"\u53ef\u80fd\u8f6c\u6362 - \u5206\u6570\u8303\u56f4",//no i18n
"crm.website.activity":"\u7f51\u7ad9\u6d3b\u52a8",//no i18n
"crm.label.By":"\u901a\u8fc7",//no i18n
"crm.chosen.searching.text":"\u641c\u7d22\u4e2d\u2026",//no i18n
"crm.label.memberstatus.is":"\u548c\u6210\u5458\u72b6\u6001\u662f",//no i18n
"crm.events.duration":"\u6301\u7eed\u65f6\u95f4",//no i18n
"crm.title.clear.name":"\u6e05\u7a7a",//no i18n
"crm.label.status.is":"\u5e76\u4e14\u72b6\u6001\u662f",//no i18n
"zia.last3.help":"\u4f1a\u8bdd\u5305\u62ec\u901a\u8bdd\u3001\u4efb\u52a1\u3001{0}\u3001\u63a5\u6536\u7684\u90ae\u4ef6\u3001\u5907\u6ce8\u3001\u8bbf\u95ee\u3001\u793e\u4ea4\u8bc4\u8bba\u3001\u6765\u81eaDesk\u7684\u652f\u6301\u8bf7\u6c42\u3002",//no i18n
"crm.label.tag.related.to":"\u6709\u5173",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "\u65b0\u7684{0}\u5c06\u88ab\u521b\u5efa\u3002",//No I18n
	"crm.krp.no.records.found" : "\u6ca1\u6709\u627e\u5230{0}\u3002",//No I18n
	"crm.module.new" : "\u65b0\u5efa{0}",//No I18n
	"crm.label.view" : "\u67e5\u770b",//No I18n
	"crm.nsocial.customers" : "\u5ba2\u6237",//No I18n
	"crm.nsocial.open.potential" : "\u6253\u5f00{0}",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "\u5176\u5b83",//No i18n
	"crm.field.length.check" : "{0}\u503c\u8d85\u8fc7\u6700\u5927\u957f\u5ea6", //No I18n
	"crm.lower.now": "\u73b0\u5728",//no i18n
	"crm.time.min.ago": "{0}\u5206\u949f\u4e4b\u524d",//no i18n
	"crm.time.mins.ago":"{0}\u5206\u949f\u4e4b\u524d",//no i18n
	"crm.time.hr.ago": "{0}\u5c0f\u65f6\u4e4b\u524d",//no i18n
	"crm.time.hrs.ago": "{0}\u5c0f\u65f6\u4e4b\u524d", //no i18n
	"AllUsers": "\u6240\u6709\u7528\u6237", //no i18n
	"crm.label.search":"\u641c\u7d22",//no i18n
	"crm.api.filterby":"\u8fc7\u6ee4\u901a\u8fc7",//no i18n
	"crm.customview.nofields.found":"--\u6ca1\u6709\u5339\u914d\u7684\u5b57\u6bb5--",//no i18n
	"crm.setup.system.ziarecommendation":"\u63a8\u8350",//no i18n
	"crm.setup.system.ziasimilarity":"\u76f8\u4f3c\u6027\u63a8\u8350",//no i18n
	"zia.similarity.smartfilter.score":"\u76f8\u4f3c\u6027\u5f97\u5206",//no i18n
	"zia.similarity.smartfilter.records":"\u7c7b\u4f3c\u7684{0} - ",//no i18n
	"zia.similarity.smartfilter.records.search":"\u663e\u793a\u76f8\u4f3c\u7684{0} -",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"\u60a8\u6700\u591a\u53ef\u4ee5\u9009\u62e9{0}\u4e2a{1}",//no i18n
	"crm.gdpr.notavailable.field":"\u4e0d\u53ef\u7528",//no i18n
	"crm.filter.label.all.products":"\u6240\u6709{0}",//no i18n
	"crm.filter.label.select.products":"\u9009\u62e9\u7684{0}",//no i18n
	"crm.reviewprocess.smart.filter":"\u5ba1\u6838\u6d41\u7a0b\u7684\u8bb0\u5f55\u72b6\u6001",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"\u8bf7\u9009\u62e9\u4e00\u4e2a\u503c\u3002",//no i18n
	"crm.segmentation.segment.score":"\u7ec6\u5206\u503c",//no i18n
	"crm.filter.label.in":"\u57fa\u4e8e",//no i18n
	"crm.filter.label.and.purchase.in":"\u5e76\u4e14\u5f88\u53ef\u80fd\u8d2d\u4e70",//no i18n
	"crm.filter.label.last.purchased":"\u5e76\u4e14\u6700\u8fd1\u4e70\u8fc7",//no i18n
	"crm.filter.label.a.day":"\u4e00\u5929",//no i18n
	"crm.filter.label.a.week":"\u4e00\u5468",//no i18n
	"crm.filter.label.a.month":"\u4e00\u4e2a\u6708",//no i18n
	"crm.cal.custom":"\u81ea\u5b9a\u4e49",//no i18n
	"crm.mb.field.common.empt":"\u503c\u4e0d\u80fd\u4e3a\u7a7a\u3002",//no i18n
	"crm.chosen.error.loading.text":"\u52a0\u8f7d\u7ed3\u679c\u65f6\u51fa\u9519",//no i18n
	"crm.filter.label.firstbuy":"\u9996\u6b21\u7684\u65f6\u95f4",//no i18n
	"crm.filter.label.cwbab":"\u4f9d\u8d56",//no i18n
	"crm.filter.label.fbt":"\u6346\u7ed1",//no i18n
	"crm.filter.label.rebuy":"\u91cd\u590d",//no i18n
	"crm.filter.label.nextbuy":"\u5e8f\u5217",//no i18n
	"crm.mxnlookup.select" : "\u5206\u914d{0}",//No I18n
	"crm.lookup.chooserecord":"\u9009\u62e9{0}",//no i18n
	"crm.record.selected":"\u9009\u4e2d\u7684{0}",//no i18n
	"crm.module.empty.message" : "\u627e\u4e0d\u5230{0}",//No I18n
	"crm.mxnlookup.selected" : "\u5df2\u5206\u914d{0}",//No I18n
	"crm.security.error" : "\u6ca1\u6709\u5145\u8db3\u7684\u6743\u9650\u6765\u6267\u884c\u8be5\u64cd\u4f5c\uff0c\u8bf7\u548c\u7ba1\u7406\u5458\u8054\u7cfb\u3002", //No I18n
	"crm.label.creator.noPermission" : "\u65e0\u6743\u8bbf\u95ee\u3002", //No I18n
	"crm.segmentation.recency" : "\u65b0\u8fd1\u6d88\u8d39",  //No I18n
	"crm.segmentation.frequency" : "\u6d88\u8d39\u9891\u7387",  //No I18n
	"crm.segmentation.monetary" : "\u6d88\u8d39\u91d1\u989d",  //No I18n
	"crm.smartfilter.related.module.msg" : "\u60a8\u4e0d\u80fd\u9009\u62e9\u4e09\u4e2a\u4ee5\u4e0a\u76f8\u5173\u7684\u6a21\u5757\u3002",  //No I18n
	"crm.smartfilter.related.module.msg1" : "\uff08\u4f8b\u5982\uff1a\u90ae\u7bb1\uff0c\u4efb\u52a1\u3001\u901a\u8bdd\u53ca\u6d3b\u52a8\uff0c\u5907\u6ce8\uff09",  //No I18n
	"crm.smartfilter.related.module.msg2 " : "\u6301\u7eed\u65f6\u95f4\u4e0d\u80fd\u4e3a\u7a7a",  //No I18n
	"crm.label.timeZone": "\u65f6\u533a", //NO I18n
	"crm.label.insufficient.privileges": "\u6743\u9650\u4e0d\u591f\uff0c\u4e0d\u80fd\u6267\u884c\u8be5\u64cd\u4f5c\uff0c\u8bf7\u8054\u7cfb\u60a8\u7684\u7ba1\u7406\u5458\u3002", //NO I18n
	"crm.filter.header.secton.system": "\u7cfb\u7edf\u5b9a\u4e49\u7684\u8fc7\u6ee4\u5668", //NO I18N
	"crm.filter.header.secton.fields": "\u6309\u5b57\u6bb5\u8fc7\u6ee4", //NO I18N
	"crm.createfield.calcinfo.new" : "\u6b64\u5b57\u6bb5\u5145\u5f53\u4e00\u4e2a\u8ba1\u7b97\u5668\uff0c\u5bf9\u60a8\u8f93\u5165\u7684\u8868\u8fbe\u5f0f\u8fdb\u884c\u8ba1\u7b97\u3002</br> <b>\u5982\uff1a20+20</b>\u5c06\u81ea\u52a8\u8f6c\u6362\u4e3a<b>40</b>",//No i18n
	"crm.lable.read.only" : "\u53ea\u8bfb\u5b57\u6bb5",//No i18n
	"crm.column.sort.asc" : "\u5347\u5e8f",//No I18n
	"crm.column.sort.desc" : "\u964d\u5e8f",//No i18n
	"crm.column.unsort" : "\u53d6\u6d88\u6392\u5e8f",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "\u5171\u4eab\u7ed9\u5ba2\u6237", //NO I18N
	"crm.label.edited": "\u5df2\u7f16\u8f91", //NO I18N
	"crm.label.edited.on": "\u7f16\u8f91\u4e8e", //NO I18N
	"crm.message.limit.exceed": "{1}\u4e2d\u53ea\u80fd\u5305\u542b{0}\u4e2a\u5b57\u7b26\u3002", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "\u4e0e\u5ba2\u6237\u5171\u4eab", //NO I18N
	"crm.button.ok" : "\u786e\u5b9a", //NO I18N
	"crm.role.already.selected" : "\u6b64\u89d2\u8272\u5df2\u9009\u62e9", //no i18n
	"crm.user.deleted": "\u7528\u6237\u5df2\u88ab\u5220\u9664",  //NO I18N
	"crm.account.closed": "\u6b64\u5e10\u6237\u5df2\u88ab\u5173\u95ed",  //NO I18N
	"crm.start.chat": "\u5f00\u59cb\u804a\u5929",  //NO I18N
	"crm.start.call": "\u5f00\u59cb\u4e00\u4e2a\u901a\u8bdd",  //NO I18N
	"crm.recipient.invalid.email" : "\u627e\u5230\u65e0\u6548\u7684\u7535\u5b50\u90ae\u4ef6", //NO I18N
	"crm.recipient.add.recipient" : "\u6dfb\u52a0\u9644\u52a0\u6536\u4ef6\u4eba", //NO I18N
	"crm.start.video.call": "\u5f00\u59cb\u4e00\u4e2a\u89c6\u9891\u901a\u8bdd",  //NO I18N //ignorei18n_start

	"crm.label.scoring.rules":"\u79ef\u5206\u89c4\u5219",
	"Score":"\u603b\u79ef\u5206",
	"Positive Score":"\u6b63\u79ef\u5206",
	"Negative Score":"\u8d1f\u79ef\u5206",
	"Touch Point Score":"\u89e6\u70b9\u79ef\u5206",
	"Positive Touch Point Score":"\u6b63\u5411\u89e6\u70b9\u79ef\u5206",
	"Negative Touch Point Score":"\u53cd\u5411\u89e6\u70b9\u79ef\u5206",
	"is\ OPEN":"\u4e3a\u201c\u6253\u5f00\u201d",//no i18n
	"is\ WON":"\u4e3a\u201c\u8d62\u5355\u5173\u95ed\u201d",//no i18n
	"is\ LOST":"\u4e3a\u201c\u4e22\u5355\u5173\u95ed\u201d",//no i18n
	"crm.potential.all.open":"\u6240\u6709\u201c\u6253\u5f00\u201d\u7684\u9636\u6bb5",//no i18n
	"crm.potential.all.won":"\u6240\u6709\u201c\u8d62\u5355\u5173\u95ed\u201d\u7684\u9636\u6bb5",//no i18n
	"crm.potential.all.lost":"\u6240\u6709\u201c\u4e22\u5355\u5173\u95ed\u201d\u7684\u9636\u6bb5",//no i18n
	"crm.label.type.minutes": "\u5728\u8fd9\u91cc\u8f93\u5165\u5206\u949f\u6570", //NO I18N

	"crm.campaign.member.status" : "\u6210\u5458\u72b6\u6001",//no i18n
	"crm.dashboard.select.type" : "\u9009\u62e9{0}",//no i18n
	"crm.campaign.service.status":"\u670d\u52a1\u72b6\u6001",//no i18n

	"crm.label.addColumn":"\u6dfb\u52a0\u5217",//no i18n
	"crm.button.clear.filter":"\u5173\u95ed\u8fc7\u6ee4\u5668",//no i18n
	"crm.button.show.filter":"\u663e\u793a\u8fc7\u6ee4\u5668",//no i18n
	"crm.las.error.user.maxlimit":"\u60a8\u6700\u591a\u53ea\u80fd\u9009\u62e920\u4e2a\u7528\u6237\u3002",//no i18n
	"crm.las.error.picklist.maxlimit":"\u60a8\u6700\u591a\u53ea\u80fd\u9009\u62e920\u4e2a\u9009\u9879\u3002",//no i18n

	"crm.fileuploader.message.responseerror": "\u4e0a\u4f20\u5931\u8d25", //NO I18N
	"crm.storage.create.error":"\u60a8\u5df2\u8fbe\u5230\u6700\u5927\u6570\u636e\u5b58\u50a8\u9650\u5236\uff0c\u56e0\u6b64\u65e0\u6cd5\u521b\u5efa\u65b0\u8bb0\u5f55\u3002",//no i18n
	"crm.storage.create.error.client":"\u7531\u4e8e\u60a8\u7684\u7ba1\u7406\u5458\u5df2\u8fbe\u5230\u5176\u6700\u5927\u5b58\u50a8\u9650\u5236\uff0c\u56e0\u6b64\u65e0\u6cd5\u521b\u5efa\u4efb\u4f55\u65b0\u8bb0\u5f55\u3002\u8bf7\u8054\u7cfb {0} \u6765\u89e3\u51b3\u8fd9\u4e2a\u95ee\u9898\u3002",//no i18n
	"crm.storage.avail.info":"(\u5269\u4f59\uff1a{0}\uff0c\u603b\u8ba1\uff1a{1})",//no i18n
	"crm.storage.error.key.manage":"\u7ba1\u7406\u60a8\u7684\u6570\u636e\u5b58\u50a8",//no i18n
	"Records":"\u8bb0\u5f55",//no i18n
	"crm.workflow.alert.additional.recipients" : "\u5176\u4ed6\u6536\u4ef6\u4eba", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "\u591a\u4e2a\u90ae\u4ef6\u5730\u5740\u4e4b\u95f4\u7528\u9017\u53f7(,)\u9694\u5f00", //NO I18N
	"crm.related.contact.account" : "\u5173\u8054\u5230{1}\u7684{0}",//No I18n
	"crm.allcontact.show" : "\u5168\u90e8{0}",//No I18n
	"crm.button.mass.show" : "\u663e\u793a",//No I18n
	"crm.msg.custom.view.not.replied" : "\u6ca1\u6709\u56de\u590d\u7684\u6d88\u606f", //NO I18N
	"crm.msg.custom.view.replied" : "\u56de\u590d\u7684\u6d88\u606f",//NO I18N
	"crm.workflow.select.recipients" : "\u9009\u62e9\u6536\u4ef6\u4eba", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"\u6ca1\u6709\u9009\u4e2d\u89d2\u8272\uff0c\u60a8\u5fc5\u987b\u81f3\u5c11\u9009\u62e9\u4e00\u4e2a\u3002",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "\u5f88\u62b1\u6b49\uff0c\u65e0\u6cd5\u5220\u9664\u9ed8\u8ba4\u914d\u7f6e\u6587\u4ef6\u3002",//NO I18N
	"crm.inv.label.add.emails" : "\u6dfb\u52a0\u90ae\u7bb1" ,//NO I18N
	"crm.prediction.analytics.filter.year":"\u53bb\u5e74",//no i18n
	"Previous\ FY":"\u4e0a\u4e00\u8d22\u5e74",//no i18n
	"Current\ FY":"\u5f53\u524d\u8d22\u5e74",//no i18n
	"Next\ FY":"\u4e0b\u4e00\u8d22\u5e74",//no i18n
	"Current\ FQ":"\u5f53\u524d\u8d22\u5b63",//no i18n
	"Next\ FQ":"\u4e0b\u4e00\u8d22\u5b63",//no i18n
	"Previous\ FQ":"\u4e0a\u4e00\u8d22\u5b63",//no i18n
	"crm.label.next.year":"\u660e\u5e74",//no i18n
	"crm.inv.label.add.emails" : "\u6dfb\u52a0\u90ae\u7bb1", //NO I18N
	"crm.picklist.sample.text":"\u793a\u4f8b\u6587\u672c",//no i18n
	"crm.more.colors":"\u66f4\u591a\u989c\u8272",//no i18n
	"crm.button.back.alone":"\u8fd4\u56de",//no i18n
	
	"crm.field.label.email":"\u7535\u5b50\u90ae\u4ef6",//no i18n
	"crm.zia.nba.feature.label":"\u4e0b\u4e00\u6b65\u6700\u4f73\u884c\u52a8",//no i18n
	"Meeting":"\u4f1a\u8bae",//no i18n
	"Tomorrow":"\u660e\u5929",//no i18n
	"crm.zia.nbx.filter.due":"\u5230\u671f\u7684",//no i18n
	"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Segment Names", // NO I18N
	"abm.abm.segment.name": "ABM Segment Name", // NO I18N
	"abm.segmentation.techniques": "ABM Segmentation Techniques", // NO I18N
	"abm.filter.by.abm.fields": "Filter By ABM Fields", // NO I18N
	"abm.rfm": "RFM", // NO I18N
	"abm.firmographics": "Firmographics", // NO I18N
	"zia.similarity.smartfilter.score":"\u76f8\u4f3c\u6027\u5f97\u5206",//no i18n
	"zia.similarity.smartfilter.records":"\u76f8\u4f3c\u7684{0}\uff1a",//no i18n
	"zia.similarity.smartfilter.records.search":"\u663e\u793a\u76f8\u4f3c\u7684{0}\uff1a",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"\u60a8\u6700\u591a\u53ef\u4ee5\u9009\u62e9{0}\u4e2a{1}",//no i18n
	"crux.custom.field.greater.than.equalto":"{0}应大于或等于{1}",
	"crux.users.selected.plural" : "已选择 {0} 个用户。",
	"crux.user.selected.singular" :"已选择 {0} 个用户。",
	"crux.criteria.empty.secondaryfield.module" : "在 {1} 模块中找不到匹配的 {0} 字段",
	"crux.criteria.empty.secondaryfield" : "无其他 {0} 字段可用于比较，请输入一个值进行比较。",
	"crux.logged.in.role.definition" : "启动记录操作的用户角色",
	"crux.max.limit.unselect" : "您无法取消选择 {0} {1} 个以上.", //NO I18N
	"crux.existing.tag" : "\u201c{0}\u201d 已选择" //NO I18N
}
